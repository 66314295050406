@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar-hide {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
  }
  /* Safari and Chrome */
  .scrollbar-hide::-webkit-scrollbar {
    display: "none";
  }
}

html,
body,
#root {
  height: 100%;
}
